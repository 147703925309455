<script>
import { setHost } from '~/utils/host.js'

export default {
  name: 'ChangeDealership',
  methods: {
    async changeDealership () {
      setHost('showroom.max.auto')
    }
  }
}
</script>

<template>
  <VBtn
    class="d-print-none"
    depressed
    @click="changeDealership"
  >
    Change Dealership
  </VBtn>
</template>
