export default {
  props: {
    logo: {
      type: String,
      default: null
    }
  },
  computed: {
    selectedLogo () {
      // if block config is not defined, use instance config
      return (!this.logo && this.$store.state.instance?.config?.logo)
        ? this.$store.state.instance?.config?.logo
        : this.logo
    }
  }
}
