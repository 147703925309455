<script>
import Logo from '@maxsystems/ui/logos/wordmark'

import ChangeDealership from './ChangeDealership.vue'
import StartOver from './StartOver'

export default {
  components: {
    Logo,
    StartOver,
    ChangeDealership
  },
  props: {
    /** Whether user is navigating from showroom.max.auto */
    showroomNavigation: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    href: 'https://maxdigital.com/digital-retailing/max-bdc/?utm_source=Demo+Site&utm_medium=Banner&utm_campaign=MAX+BDC',
    year: new Date().getFullYear()
  })
}
</script>

<template>
  <footer
    class="footer--bdc primary font-size-xxs pa-6 d-flex align-center justify-center justify-md-space-between flex-wrap"
  >
    <a
      :href="href"
      class="d-flex flex-column flex-sm-row"
    >
      <p class="mr-1">
        Powered by
      </p>
      <Logo
        flat
        height="30"
        color-secondary="#FFF"
      />
    </a>
    <span>
      &copy; {{ year }}
      <a :href="href">MAX Digital, LLC.</a>
      All Rights Reserved.
    </span>
    <div
      v-if="showroomNavigation"
      class="footer--bdc__actions d-flex align-center"
    >
      <ChangeDealership />
      <StartOver
        color="secondary d-print-none"
        class="ma-0"
      />
    </div>
  </footer>
</template>

<style lang="scss">
.footer--bdc {
  color: #FFF;
  gap: 8px;

  a {
    color: #FFF;
    text-decoration: none;
  }

  .v-btn {
    text-transform: none;
  }

  &__logo p {
    display: inline;
    vertical-align: text-bottom;
  }

  &__actions {
    gap: 8px;
  }
}
</style>
