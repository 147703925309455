<script>
import { mapGetters } from 'vuex'

import { Icon } from '@maxsystems/ui/elements'

import garage from '../icons/garage.svg'

export default {
  name: 'BtnFavorites',
  components: {
    Icon
  },
  props: {
    size: {
      type: [String, Number],
      default: 26
    },
    to: {
      type: String,
      default: '/board'
    }
  },
  data: () => ({
    icons: {
      garage,
      heart: 'M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z'
    }
  }),
  computed: mapGetters('opportunity/board', ['favorites'])
}
</script>

<template>
  <span class="btn__favorites">
    <VBtn
      :ripple="false"
      :to="to"
      color="primary"
      icon
      text
    >
      <Icon
        :src="icons.garage"
        :width="size"
      />
    </VBtn>
    <svg
      v-if="favorites.length"
      class="btn__favorites__count"
      :width="size * 0.8"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#FFF"
        fill="#FFF"
        stroke-width="4"
        :d="icons.heart"
      />
      <path
        fill="currentColor"
        :d="icons.heart"
      />
      <text
        x="50%"
        y="50%"
        dominant-baseline="middle"
        text-anchor="middle"
        fill="#FFF"
      >
        {{ favorites.length }}
      </text>
    </svg>
  </span>
</template>

<style lang="scss">
.btn__favorites {
  display: block;
  position: relative;

  & .v-btn {
    margin-right: 12px;

    &--active::before,
    &:hover::before,
    &:focus::before {
      background-color: transparent;
    }
  }

  &__count {
    bottom: 12px;
    color: var(--v-favorite-base);
    pointer-events: none;
    position: absolute;
    right: 1px;

    text {
      font-size: 10px;
      font-weight: 700;
    }
  }
}
</style>
