import { VBtn } from 'vuetify/lib'

import { ConfirmDialog } from '@maxsystems/ui/patterns'

export default {
  name: 'StartOver',
  methods: {
    async startOver () {
      if (!await ConfirmDialog.open({
        title: 'This will clear all saved customer and vehicle info. Do you want to continue?',
        confirm: 'Continue'
      })) return

      this.$store.dispatch('search/reset')
      await this.$store.dispatch('opportunity/create')

      if (this.$router.currentRoute.path === '/') return
      this.$router.push('/')
    }
  },
  render (h) {
    return h(VBtn, {
      attrs: this.$attrs,
      props: {
        color: 'primary d-print-none',
        depressed: true,
        ...this.$attrs
      },
      on: { click: this.startOver }
    }, [
      'Start Over'
    ])
  }
}
